import { ethers } from 'ethers';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast, ToastContainer } from 'react-toastify';
import Logo from '../../assets/logo.png';
import { networks } from '../../config/address';
import { useConfig } from '../../context/ConfigProvider';
import {
    useWalletDispatch,
    useWalletState,
} from '../../context/WalletProvider';
import { sliceAddress } from '../../utils/address';
import { isMMError } from '../../utils/error';

export const Navbar: React.FC = () => {
    const { login, checkValidNetwork } = useWalletDispatch();
    const { address, isValidNetwork, isProvider } = useWalletState();
    const config = useConfig();

    const handleClickConnectWallet = () => {
        if (!window.ethereum) {
            return;
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        login(provider);
    };

    const handleSwitchToValidNetwork = async () => {
        if (!window.ethereum) {
            return;
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        try {
            await provider.send('wallet_switchEthereumChain', [
                { chainId: config.networkId },
            ]);

            checkValidNetwork();
        } catch (err: unknown) {
            if (isMMError(err) && err.code === 4902) {
                try {
                    const conf = networks.find(
                        (el) => el.chainId === config.networkId
                    );
                    if (!conf) {
                        throw new Error('Network not supported');
                    }
                    await provider.send('wallet_addEthereumChain', [
                        {
                            chainId: conf.chainId,
                            chainName: conf.chainName,
                            nativeCurrency: conf.nativeCurrency,
                            rpcUrls: conf.rpcUrls[0],
                            blockExplorerUrls: conf.blockExplorerUrls,
                        },
                    ]);
                    checkValidNetwork();
                } catch (err: unknown) {
                    toast.error(
                        `You need add ${config.networkId} network id to your metamask`,
                        {
                            className: 'black-background',
                        }
                    );
                }
            }
        }
    };

    return (
        <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-darkpool-dark">
            <ToastContainer
                autoClose={false}
                toastClassName={() => 'bg-black'}
            />
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <span className="self-center text-lg font-semibold whitespace-nowrap dark:text-white">
                    <a href="https://darkpool.one/" className="flex">
                        <img src={Logo} alt="Dark Pool One" className="w-40" />
                    </a>
                </span>
                <div className="flex md:order-2">
                    {address && (
                        <div className="dark:text-white">
                            {sliceAddress(address)}
                        </div>
                    )}

                    {!address && !isValidNetwork && isProvider && (
                        <button
                            type="button"
                            onClick={handleSwitchToValidNetwork}
                            className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 bg-red-600"
                        >
                            <FormattedMessage id="changeNetwork" />
                        </button>
                    )}
                    {!address && isValidNetwork && isProvider && (
                        <button
                            type="button"
                            onClick={handleClickConnectWallet}
                            className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 bg-darkpool-dimmed"
                        >
                            <FormattedMessage id="connectWallet" />
                        </button>
                    )}
                    {!isProvider && (
                        <button
                            type="button"
                            className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 bg-red-600"
                        >
                            <FormattedMessage id="installMM" />
                        </button>
                    )}
                </div>
            </div>
        </nav>
    );
};
