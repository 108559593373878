import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRoundDispatch } from '../../context/RoundsProvider';
import { useWalletState } from '../../context/WalletProvider';
import { CrowdSaleService } from '../../service/CrowdSaleService';
import { abi } from '../../contract/PaypoolV1Crowdsale.json';

interface IProps {
    value: number;
    roundAddress: string;
    roundId: number;
}

export const ClaimTge: React.FC<IProps> = ({
    value,
    roundAddress,
    roundId,
}) => {
    const { signer, address } = useWalletState();
    const { loadRounds, resetTge } = useRoundDispatch();

    const handleClickClaim = async () => {
        if (!signer || !value) {
            return;
        }
        const crowdsale = new CrowdSaleService(roundAddress, abi, signer);
        const tx = await crowdsale.claim();
        await tx.wait();

        resetTge(roundId, value);

        setTimeout(() => {
            loadRounds(address, signer);
        }, 1000);
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <button
                    id="claimTge"
                    onClick={handleClickClaim}
                    className={`text-white ${
                        value
                            ? 'bg-darkpool-green'
                            : 'bg-darkpool-dimmed cursor-default'
                    } rounded-lg text-sm px-5 py-3 text-center`}
                >
                    <FormattedMessage id="claimTge" /> {value} BRI
                </button>
            </div>
        </>
    );
};
