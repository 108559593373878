import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRoundDispatch } from '../../context/RoundsProvider';
import { useWalletState } from '../../context/WalletProvider';
import { VestingService } from '../../service/VestingService';

interface IProps {
    value: number;
    vestingAddress: string;
    tokenAddress: string;
    roundId: number;
}

export const Claim: React.FC<IProps> = ({
    value,
    vestingAddress,
    tokenAddress,
    roundId,
}) => {
    const { signer, address } = useWalletState();
    const { resetClaim, loadRounds } = useRoundDispatch();

    const handleClickClaim = async () => {
        if (!signer || !value) {
            return;
        }
        const vestingService = new VestingService(vestingAddress, signer);
        const res = await vestingService.release(tokenAddress);
        await res.wait();
        resetClaim(roundId);

        setTimeout(() => {
            loadRounds(address, signer);
        }, 1000);
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <button
                    id="claim"
                    onClick={handleClickClaim}
                    className={`text-white ${
                        value
                            ? 'bg-darkpool-green'
                            : 'bg-darkpool-dimmed cursor-default'
                    } rounded-lg text-sm px-5 py-3 text-center`}
                >
                    <FormattedMessage id="claim" /> {value} BRI
                </button>
            </div>
        </>
    );
};
