export const en = {
    name: 'Name',
    buy: 'Buy',
    owned: 'Owned',
    price: 'Price',
    claim: 'Claim',
    available: 'Available',
    cancel: 'Cancel',
    max: 'Max {amount}',
    tokenLimit: 'token limit',
    tokenLeft: '{amount} token left',
    amountIncorrect: 'Amount of token is incorrect',
    connectWallet: 'Connect Wallet',
    privateSale: 'Private Sale',
    byuTokenInfo: 'You can byu easily BRI token in our private rounds',
    goToWebsite: 'Go to the website',
    importToken: 'Import token',
    fillAndBuy: 'Fill and buy token',
    allowedToBuy: 'You are allowed to buy {amount} {token} token',
    haveToken: 'At the moment you bought {amount} {token} token',
    locked: 'locked',
    withdrawn: 'Withdrawn',
    confirm: 'Confirm',
    roundClosed: 'Round is closed',
    changeNetwork: 'Change network',
    cliffStart: 'Cliff start: {date}',
    vestingStart: 'Vesting start: {date}',
    installMM: 'Install metamask',
    lockedVest: 'Lock in vesting',
    lockedTge: 'Lock in TGE',
    claimTge: 'Claim TGE',
    'error.transactionRejected': 'Please confirm transaction to proceed',
    'error.transactionRejectedTitle': 'Transaction rejected',
    'error.insufficientFunds': "You don't have enough funds to buy token",
    'error.insufficientFundsTitle': 'Insufficient funds',
};
