const errors = [4001, 4902, -32603] as const;
type TError = typeof errors[number];

export class MMError extends Error {
    constructor(
        message: string,
        public readonly code: TError,
        public readonly data?: { code: number; message: string }
    ) {
        super(message);
    }
}

export const isMMError = (error: unknown): error is MMError => {
    return errors.includes((error as MMError).code);
};
