/* istanbul ignore file */
import { IRoundDTO } from '../types/round';
import axios from 'axios';
import { isAddress } from 'ethers/lib/utils';

export const ApiRequestService = {
    baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:8088',

    async getRounds(address: string): Promise<IRoundDTO[]> {
        if (!isAddress(address)) {
            return [];
        }
        try {
            const response = await axios.get<{ investmentRounds: IRoundDTO[] }>(
                `${this.baseUrl}/investors/${address}`
            );
            return response.data.investmentRounds;
        } catch (err) {
            console.log(err);
            return [];
        }
    },
};
