/* istanbul ignore file */
import { ethers } from 'ethers';
import { abi } from '../contract/TokenVesting.json';

interface IWait {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    wait(): Promise<any>;
}

export class VestingService {
    private instance: ethers.Contract;
    constructor(
        public readonly contractAddress: string,
        public readonly signer: ethers.Signer
    ) {
        this.instance = new ethers.Contract(
            this.contractAddress,
            abi,
            this.signer
        );
    }

    public async releasableAmount(tokenAddress: string): Promise<number> {
        try {
            return await this.instance.releasableAmount(tokenAddress);
        } catch (err) {
            return Promise.resolve(0);
        }
    }

    public async release(tokenAddress: string): Promise<IWait> {
        return await this.instance.release(tokenAddress);
    }

    public async vestedAmount(tokenAddress: string): Promise<number> {
        return await this.instance.vestedAmount(tokenAddress);
    }
}
