import { ethers } from 'ethers';
import React, { useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { ApiRequestService } from '../service/ApiRequestService';
import { availableRound } from '../utils/availableRound';
import { IRound } from '../types/round';
import { useConfig } from './ConfigProvider';

enum ERoundAction {
    SET_ROUND,
}

interface ISetRound {
    type: ERoundAction.SET_ROUND;
    payload: { round: IRound[]; isLoaded?: boolean; err?: boolean };
}

interface IInitialState {
    round: IRound[];
    isLoaded: boolean;
    err: boolean;
}

type actionTypes = ISetRound;

interface IMethods {
    loadRounds: (
        userWalletAddress: string,
        signer: ethers.Signer
    ) => Promise<void>;

    resetClaim: (roundId: number) => void;
    resetTge: (roundId: number, value: number) => void;
}
const initialState: IInitialState = {
    round: [],
    isLoaded: false,
    err: false,
};

const roundsReducer = (draft: IInitialState, action: actionTypes) => {
    switch (action.type) {
        case ERoundAction.SET_ROUND:
            {
                draft.round = action.payload.round;
                draft.isLoaded = action.payload.isLoaded || draft.isLoaded;
                draft.err = action.payload.err || draft.err;
            }
            break;
    }
};

const RoundStateContext = React.createContext<IInitialState | undefined>(
    undefined
);
const RoundDispatchContext = React.createContext<IMethods | undefined>(
    undefined
);

export const RoundsProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useImmerReducer(roundsReducer, initialState);
    const { decimal } = useConfig();

    const loadRounds = async (
        userWalletAddress: string,
        signer: ethers.Signer
    ) => {
        const rounds = await (
            await ApiRequestService.getRounds(userWalletAddress)
        ).sort(
            (a, b) =>
                new Date(b.createdAt).valueOf() -
                new Date(a.createdAt).valueOf()
        );

        try {
            const availableRounds = await availableRound(
                rounds,
                signer,
                userWalletAddress,
                decimal
            );

            dispatch({
                type: ERoundAction.SET_ROUND,
                payload: { round: availableRounds, isLoaded: true },
            });
        } catch {
            dispatch({
                type: ERoundAction.SET_ROUND,
                payload: { round: [], isLoaded: true, err: true },
            });
        }
    };

    const resetClaim = (roundId: number) => {
        const round = state.round.map((el) =>
            el.id === roundId ? { ...el, claim: 0 } : { ...el }
        );
        dispatch({
            type: ERoundAction.SET_ROUND,
            payload: { round: round },
        });
    };

    const resetTge = (roundId: number, value: number) => {
        const round = state.round.map((el) =>
            el.id === roundId ? { ...el, claimedTge: value } : { ...el }
        );
        dispatch({
            type: ERoundAction.SET_ROUND,
            payload: { round: round },
        });
    };

    return (
        <RoundDispatchContext.Provider
            value={{ loadRounds, resetClaim, resetTge }}
        >
            <RoundStateContext.Provider value={state}>
                {children}
            </RoundStateContext.Provider>
        </RoundDispatchContext.Provider>
    );
};

export const useRoundState = (): IInitialState => {
    const ctx = useContext(RoundStateContext);

    if (!ctx) {
        throw new Error('Component beyond Round state context, please connect');
    }

    return ctx;
};

export const useRoundDispatch = (): IMethods => {
    const ctx = useContext(RoundDispatchContext);

    if (!ctx) {
        throw new Error(
            'Component beyond Round dispatch context, please connect'
        );
    }

    return ctx;
};
