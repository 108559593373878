import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Hero } from '../components/hero';
import { RoundBuy } from '../components/roundActive';
import { RoundClaim } from '../components/roundClosed';
import { useRoundDispatch, useRoundState } from '../context/RoundsProvider';
import { useWalletState } from '../context/WalletProvider';

export const Rounds: React.FC = () => {
    const { loadRounds } = useRoundDispatch();
    const { signer, address } = useWalletState();
    const { round, isLoaded, err } = useRoundState();

    useEffect(() => {
        if (!signer || !address) {
            return;
        }
        loadRounds(address, signer);
    }, [address]);

    return (
        <>
            <div className="flex justify-center px-7">
                <div className="mt-14 w-full max-w-screen-lg bg-darkpool-dimmed rounded-xl p-7">
                    <h3 className="text-3xl font-bold  text-gray-900 dark:text-white">
                        <FormattedMessage id="privateSale" />
                    </h3>
                    <Hero />
                    <div className="mt-4">
                        {round.map((el) => (
                            <div key={el.id} className="mb-5 bg-darkpool-grey">
                                {el.closed ? (
                                    <RoundClaim {...el} /> // nosemgrep
                                ) : (
                                    <RoundBuy {...el} /> // nosemgrep
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {isLoaded && address && !round.length && !err && (
                <div className="text-white px-7 max-w-screen-lg mx-auto mt-24 flex justify-center">
                    <div>
                        <div className="text-center">
                            Your wallet is not whitelisted
                        </div>
                        <div className="text-center">
                            If this is an error please contact
                        </div>
                        <div className="text-center">
                            help@brightpool.finance
                        </div>
                    </div>
                </div>
            )}
            {isLoaded && err && (
                <div className="text-white px-7 max-w-screen-lg mx-auto mt-24 flex justify-center">
                    <div>
                        <div className="text-center">
                            Something went wrong with current network
                        </div>
                        <div className="text-center">
                            If this is an error please contact
                        </div>
                        <div className="text-center">
                            help@brightpool.finance
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
