import { ethers } from 'ethers';
import React, { useContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { Nullable } from '../types/Nullable';
import { useConfig } from './ConfigProvider';

enum EWalletAction {
    LOGIN,
    CHECK_NETWORK,
    CHECK_PROVIDER,
}

interface ILogin {
    type: EWalletAction.LOGIN;
    payload: Pick<
        IInitialState,
        'address' | 'provider' | 'signer' | 'networkId'
    >;
}

interface ICheckNetwork {
    type: EWalletAction.CHECK_NETWORK;
    payload: boolean;
}

interface ICheckProvider {
    type: EWalletAction.CHECK_PROVIDER;
    payload: boolean;
}

interface IInitialState {
    address: string;
    provider: Nullable<ethers.providers.Web3Provider>;
    signer: Nullable<ethers.Signer>;
    networkId: number;
    isValidNetwork: boolean;
    isProvider: boolean;
}

const initialState: IInitialState = {
    address: '',
    provider: null,
    signer: null,
    networkId: 0,
    isValidNetwork: false,
    isProvider: false,
};

interface IMethods {
    login: (provider: ethers.providers.Web3Provider) => void;
    checkValidNetwork: () => void;
}

type actionTypes = ILogin | ICheckNetwork | ICheckProvider;

const walletReducer = (draft: IInitialState, action: actionTypes) => {
    switch (action.type) {
        case EWalletAction.LOGIN:
            {
                draft.address = action.payload.address;
                draft.provider = action.payload.provider;
                draft.signer = action.payload.signer;
                draft.networkId = action.payload.networkId;
            }
            break;
        case EWalletAction.CHECK_NETWORK:
            {
                draft.isValidNetwork = action.payload;
            }
            break;
        case EWalletAction.CHECK_PROVIDER:
            {
                draft.isProvider = action.payload;
            }
            break;
    }
};

const WalletStateContext = React.createContext<IInitialState | undefined>(
    undefined
);
const WalletDispatchContext = React.createContext<IMethods | undefined>(
    undefined
);

export const WalletProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useImmerReducer(walletReducer, initialState);
    const config = useConfig();

    const login = async (provider: ethers.providers.Web3Provider) => {
        await provider.send('eth_requestAccounts', []);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        const networkId = (await provider.getNetwork()).chainId;

        dispatch({
            type: EWalletAction.LOGIN,
            payload: {
                provider,
                signer,
                address,
                networkId,
            },
        });
    };

    const checkValidNetwork = async () => {
        if (!window.ethereum) {
            dispatch({
                type: EWalletAction.CHECK_PROVIDER,
                payload: false,
            });
            return;
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const networkId = (await provider.getNetwork()).chainId;
        const check = Number(config.networkId) === networkId;
        dispatch({
            type: EWalletAction.CHECK_PROVIDER,
            payload: true,
        });
        dispatch({
            type: EWalletAction.CHECK_NETWORK,
            payload: check,
        });
    };

    useEffect(() => {
        checkValidNetwork();
    }, [config.networkId]);

    return (
        <WalletDispatchContext.Provider value={{ login, checkValidNetwork }}>
            <WalletStateContext.Provider value={state}>
                {children}
            </WalletStateContext.Provider>
        </WalletDispatchContext.Provider>
    );
};

export const useWalletState = (): IInitialState => {
    const ctx = useContext(WalletStateContext);

    if (!ctx) {
        throw new Error(
            'Component beyond Wallet state context, please connect'
        );
    }

    return ctx;
};

export const useWalletDispatch = (): IMethods => {
    const ctx = useContext(WalletDispatchContext);

    if (!ctx) {
        throw new Error(
            'Component beyond Wallet dispatch context, please connect'
        );
    }

    return ctx;
};
