import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.scss';

Sentry.init({
    dsn: 'https://67d1fefe75a84d758069a1f2db611aea@o1097380.ingest.sentry.io/6118803',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    denyUrls: ['localhost'],
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
