import React, { useContext, useState } from 'react';
import Modal from '.';

interface IModalContext {
    showModal: (_title: string, _content: string) => void;
}

const ModalContext = React.createContext({
    showModal: (_title: string, _content: string) => {
        return;
    },
});

export const ModalProvider: React.FC = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    return (
        <ModalContext.Provider
            value={{
                showModal: (title, content) => {
                    setTitle(title);
                    setContent(content);
                    setIsVisible(true);
                },
            }}
        >
            <Modal
                title={title}
                content={content}
                isVisible={isVisible}
                close={() => setIsVisible(false)}
            />
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = (): IModalContext => {
    const ctx = useContext(ModalContext);

    if (!ctx) {
        throw new Error(
            'Component beyond modal state context, please wrap component'
        );
    }

    return ctx;
};
