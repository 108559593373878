import { BigNumber, ethers } from 'ethers';
import { CrowdSaleService } from '../service/CrowdSaleService';
import { IRound, IRoundDTO } from '../types/round';
import { VestingService } from '../service/VestingService';
import { Erc20Service } from '../service/Erc20Service';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { abi } from '../contract/PaypoolV1Crowdsale.json';
import date from 'date-and-time';

export const availableRound = async (
    rounds: IRoundDTO[],
    signer: ethers.Signer,
    userAddress: string,
    decimal: number
): Promise<IRound[]> => {
    const availableRound = (
        await Promise.all(
            rounds.map(async (round) => {
                const crowdsaleService = new CrowdSaleService(
                    round.deployedAddress,
                    abi,
                    signer
                );

                const tgeAward = await crowdsaleService.tgeAward(userAddress);

                const getClaimLeft = await crowdsaleService.getClaimLeft(
                    userAddress
                );

                const claimed = await crowdsaleService.claimed(userAddress);

                const cap = await crowdsaleService.cap();

                const weiRaised = await crowdsaleService.weiRaised();

                const isWhiteList = await crowdsaleService.isWhitelisted(
                    userAddress
                );

                if (!isWhiteList) {
                    return null;
                }

                const tokenDPXAddress = await crowdsaleService.token();
                const closed = await crowdsaleService.hasClosed();
                const vestingAddress = await crowdsaleService.getVesting(
                    userAddress
                );
                const ownToken = await crowdsaleService.getContribution(
                    userAddress
                );

                const userHardCap = await crowdsaleService.getCap(userAddress);

                const vestingService = new VestingService(
                    vestingAddress,
                    signer
                );

                const erc20Service = new Erc20Service(tokenDPXAddress, signer);
                const lockToken = await erc20Service.balanceOf(vestingAddress);
                const tokenVested = await vestingService.releasableAmount(
                    tokenDPXAddress
                );

                //  Cliff calculation
                const vestingExists =
                    round.vestingCliff !== undefined &&
                    round.vestingCliff !== null;
                const endCliff = date.addDays(
                    new Date(round.endDate),
                    round.vestingCliff
                );

                const cliffSecond = date
                    .subtract(new Date(endCliff), new Date(round.endDate))
                    .toSeconds();

                const secondLeft = date
                    .subtract(new Date(endCliff), new Date())
                    .toSeconds();

                const cliffPercent = +Number(
                    100 - (secondLeft / cliffSecond) * 100
                ).toFixed(2);

                // Vesting calculation

                const endVesting = date.addDays(
                    new Date(endCliff),
                    round.amountMonthForPurchased
                );

                const vestingSecond = date
                    .subtract(new Date(endVesting), new Date(endCliff))
                    .toSeconds();

                const secondLeftVesting = date
                    .subtract(new Date(endVesting), new Date())
                    .toSeconds();

                const cliffPercentVesting = +Number(
                    100 - (secondLeftVesting / vestingSecond) * 100
                ).toFixed(2);

                const userHardCapCalc = BigNumber.from(userHardCap)
                    .mul(round.price)
                    .div('1000000000000000000')
                    .toString();

                const ownTokenCalc = BigNumber.from(ownToken)
                    .mul(round.price)
                    .div(String(10 ** decimal))
                    .toString();

                return {
                    id: round.id,
                    roundAddress: round.deployedAddress,
                    tokenAddress: tokenDPXAddress,
                    vestingAddress,
                    cliffPercent: cliffPercent < 0 ? 0 : cliffPercent,
                    cliffEnd: date.format(new Date(endCliff), 'DD-MM-YYYY'),
                    vestingEnd: date.format(new Date(endVesting), 'DD-MM-YYYY'),
                    vestingPercent:
                        cliffPercentVesting < 0 ? 0 : cliffPercentVesting,
                    endDate: date.format(new Date(round.endDate), 'DD-MM-YYYY'),
                    vestingStart: vestingExists
                        ? date.format(new Date(endCliff), 'DD-MM-YYYY')
                        : 'N/A',
                    userHardCap: Number(formatEther(userHardCapCalc)),
                    roundHardCap:
                        Number(formatEther(round.price)) *
                        Number(formatUnits(cap)),
                    ratioToken:
                        1 / Number(formatUnits(round.price, 36 - decimal)),
                    ownToken: Number(formatUnits(ownTokenCalc, 36 - decimal)),
                    roundName: round.name,
                    soldToken:
                        Number(formatUnits(weiRaised)) *
                        Number(formatEther(round.price)),
                    closed,
                    claim: Number((tokenVested / 1e18).toFixed(3)),
                    lockToken: Number((lockToken / 1e18).toFixed(3)),
                    tgeAward: Number((tgeAward / 1e18).toFixed(3)),
                    claimedTge: Number((claimed / 1e18).toFixed(3)),
                    claimLeft: Number((getClaimLeft / 1e18).toFixed(3)),
                };
            })
        )
    ).filter(Boolean);

    return availableRound as IRound[];
};
