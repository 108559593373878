import { ethers } from 'ethers';

export const sliceAddress = (address: string): string => {
    const first = address.slice(0, 6);
    const last = address.slice(address.length - 6);

    return `${first}...${last}`;
};

export const isAddress = (address: string): boolean => {
    return ethers.utils.isAddress(address);
};
