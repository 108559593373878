import { utils } from 'ethers';

/* istanbul ignore file */

export const networks = [
    {
        chainId: utils.hexValue(80001),
        chainName: 'Polygon Testnet',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
        },
        rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    },
    {
        chainId: utils.hexValue(137),
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
        },
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://www.polygonscan.com'],
    },
    {
        chainId: utils.hexValue(1),
        chainName: 'ETH',
        rpcUrls: ['https://test/'],
        blockExplorerUrls: ['https://test/'],
        nativeCurrency: {
            decimals: 18,
            name: 'ETH',
            symbol: 'ETH',
        },
    },
];
