import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ModalProvider } from './components/modal/context';
import { ConfigProvider } from './context/ConfigProvider';
import { LanguageProvider } from './context/LanguageProvider';
import { RoundsProvider } from './context/RoundsProvider';
import { Layout } from './layout';
import { WalletProvider } from './context/WalletProvider';
import { Rounds } from './views/Rounds';

export const App: React.FC = () => {
    return (
        <LanguageProvider>
            <ConfigProvider>
                <WalletProvider>
                    <Layout>
                        <ModalProvider>
                            <Routes>
                                <Route
                                    index
                                    element={
                                        <RoundsProvider>
                                            <Rounds />
                                        </RoundsProvider>
                                    }
                                />
                            </Routes>
                        </ModalProvider>
                    </Layout>
                </WalletProvider>
            </ConfigProvider>
        </LanguageProvider>
    );
};

export default App;
