import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '../../context/ConfigProvider';

export const Hero: React.FC = () => {
    const config = useConfig();
    const handleClickImportToken = async () => {
        try {
            await window.ethereum.sendAsync({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: config.DPX_TOKEN,
                        symbol: config.tokenSymbol,
                        decimals: config.tokenDecimals,
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="bg-darkpool-grey p-7 mt-4 lg:flex justify-between">
            <div>
                <p className="text-base tracking-tight text-darkpool-textGrey">
                    {config.tokenSymbol} Address
                </p>
                <p className="text-xl tracking-tight font-medium text-white mt-3 break-all">
                    {config.DPX_TOKEN}
                </p>
            </div>
            <div className="justify-center items-center mt-4 flex sm:space-y-0 sm:space-x-4">
                {config.tokenSymbol && (
                    <div
                        onClick={handleClickImportToken}
                        className="text-white bg-darkpool-green rounded-lg text-sm px-5 py-3 text-center cursor-pointer"
                    >
                        <div className="text-left">
                            <div className="font-sans text-sm font-semibold">
                                <FormattedMessage id="importToken" />
                            </div>
                        </div>
                    </div>
                )}
                <a
                    href="https://brightpool.finance/"
                    className="text-darkpool-green text-base border-2 rounded-lg border-darkpool-green px-8 py-3 ml-8 mt-0"
                >
                    <div className="text-left">
                        <div className="font-sans text-sm font-semibold">
                            <FormattedMessage id="goToWebsite" />
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};
