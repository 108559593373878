import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useConfig } from '../../context/ConfigProvider';
import { IRound } from '../../types/round';
import { Claim } from '../claim';
import { ClaimTge } from '../claimTge';
import { ProgressBar } from '../progressBar';

export const RoundClaim: React.FC<IRound> = ({
    id,
    roundName,
    claim,
    soldToken,
    roundHardCap,
    vestingAddress,
    tokenAddress,
    lockToken,
    vestingEnd,
    vestingPercent,
    cliffPercent,
    cliffEnd,
    tgeAward,
    roundAddress,
    claimedTge,
    claimLeft,
}) => {
    const config = useConfig();

    return (
        <div className="rounded-lg border bg-darkpool-gray p-7 border-darkpool-grey">
            <ProgressBar
                roundName={roundName}
                soldToken={soldToken}
                roundHardCap={roundHardCap}
            />

            <div className="mt-5 md:flex justify-between">
                <div>
                    <p className="text-xl tracking-tight text-gray-900 dark:text-white mb-2">
                        <FormattedMessage id="roundClosed" />
                    </p>
                    <p className="text-xl tracking-tight text-gray-900 dark:text-white">
                        <FormattedMessage id="lockedVest" /> {lockToken}{' '}
                        {config.tokenSymbol}
                    </p>
                    {tgeAward - claimedTge !== 0 && (
                        <p className="text-xl tracking-tight text-gray-900 dark:text-white mt-4">
                            <FormattedMessage id="lockedTge" /> {tgeAward}{' '}
                            {config.tokenSymbol}{' '}
                        </p>
                    )}
                </div>
                {cliffPercent <= 100 && (
                    <div className="flex flex-col justify-center mt-6 md:mt-0">
                        <p className="text-white">Cliff end: {cliffEnd}</p>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-darkpool-grey3">
                            <div
                                className="bg-darkpool-green h-2.5 rounded-full"
                                style={{ width: `${cliffPercent}%` }}
                            ></div>
                        </div>
                    </div>
                )}
                {vestingPercent <= 100 && (
                    <div className="flex flex-col justify-center mt-2 md:mt-0 md:ml-2 ">
                        <p className="text-white">Vesting end: {vestingEnd}</p>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-darkpool-grey3">
                            <div
                                className="bg-darkpool-green h-2.5 rounded-full"
                                style={{ width: `${vestingPercent}%` }}
                            ></div>
                        </div>
                    </div>
                )}
                <div className="mt-8 md:mt-0 flex">
                    <div className="md:ml-2 flex items-center">
                        <Claim
                            value={claim}
                            vestingAddress={vestingAddress}
                            tokenAddress={tokenAddress}
                            roundId={id}
                        />
                    </div>
                    {tgeAward - claimedTge !== 0 && (
                        <div className="ml-2 flex items-center">
                            <ClaimTge
                                value={claimLeft}
                                roundAddress={roundAddress}
                                roundId={id}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
