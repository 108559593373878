/* istanbul ignore file */
import { BigNumber, ethers } from 'ethers';

interface IWait {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    wait(): Promise<any>;
}
export class CrowdSaleService {
    private instance: ethers.Contract;
    constructor(
        public readonly contractAddress: string,
        public readonly abi: ethers.ContractInterface,
        public readonly signer: ethers.Signer
    ) {
        this.instance = new ethers.Contract(
            this.contractAddress,
            this.abi,
            this.signer
        );
    }

    public async buyTokens(
        beneficiary: string,
        fundingToken: string,
        amount: string | BigNumber
    ): Promise<IWait> {
        return await this.instance.buyTokens(beneficiary, fundingToken, amount);
    }

    public async cap(): Promise<number> {
        return await this.instance.cap();
    }

    public async setCap(
        address: string,
        value: string | bigint
    ): Promise<void> {
        return await this.instance.setCap(address, value);
    }

    public async capReached(): Promise<boolean> {
        return await this.instance.capReached();
    }

    public async closingTime(): Promise<number> {
        return await this.instance.closingTime();
    }

    public async openingTime(): Promise<number> {
        return await this.instance.openingTime();
    }

    public async finalize(fundingToken: string): Promise<void> {
        return await this.instance.finalize(fundingToken);
    }

    public async getCap(address: string): Promise<number> {
        return await this.instance.getCap(address);
    }

    public async getVesting(address: string): Promise<string> {
        return await this.instance.getVesting(address);
    }

    public async getWhitelistedCount(): Promise<number> {
        return await this.instance.getWhitelistedCount();
    }

    public async hasClosed(): Promise<boolean> {
        return await this.instance.hasClosed();
    }

    public async isCapper(address: string): Promise<boolean> {
        return await this.instance.isCapper(address);
    }

    public async isFinalized(): Promise<boolean> {
        return await this.instance.isFinalized();
    }

    public async isWhitelistAdmin(address: string): Promise<boolean> {
        return await this.instance.isWhitelistAdmin(address);
    }

    public async isWhitelisted(address: string): Promise<boolean> {
        return await this.instance.isWhitelisted(address);
    }

    public async owner(): Promise<string> {
        return await this.instance.owner();
    }

    public async rate(): Promise<number> {
        return await this.instance.rate();
    }

    public async removeWhitelisted(address: string): Promise<void> {
        return await this.instance.removeWhitelisted(address);
    }

    public async addWhitelisted(address: string): Promise<void> {
        return await this.instance.addWhitelisted(address);
    }

    public async token(): Promise<string> {
        return await this.instance.token();
    }

    public async getContribution(address: string): Promise<number> {
        return await this.instance.getContribution(address);
    }

    public async weiRaised(): Promise<number> {
        return await this.instance.weiRaised();
    }

    public async tgeAward(address: string): Promise<any> {
        return await this.instance.tgeAward(address);
    }

    public async vestingStartAward(address: string): Promise<any> {
        return await this.instance.vestingStartAward(address);
    }

    public async getClaimLeft(address: string): Promise<any> {
        return await this.instance.getClaimLeft(address);
    }

    public async claimed(address: string): Promise<any> {
        return await this.instance.claimed(address);
    }

    public async getMaxClaimAmount(address: string): Promise<any> {
        return await this.instance.getMaxClaimAmount(address);
    }

    public async claim(): Promise<IWait> {
        return await this.instance.claim();
    }
}
