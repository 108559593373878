/* istanbul ignore file */
import { ethers } from 'ethers';
import abi from '../contract/erc20.json';

interface IWait {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    wait(): Promise<any>;
}

export class Erc20Service {
    private instance: ethers.Contract;
    constructor(
        public readonly contractAddress: string,
        public readonly signer: ethers.Signer
    ) {
        this.instance = new ethers.Contract(
            this.contractAddress,
            abi,
            this.signer
        );
    }

    public async name(): Promise<string> {
        return await this.instance.name();
    }

    public async symbol(): Promise<string> {
        return await this.instance.symbol();
    }

    public async decimals(): Promise<number> {
        return await this.instance.decimals();
    }

    public async totalSupply(): Promise<number> {
        return await this.instance.totalSupply();
    }

    public async balanceOf(addressOwner: string): Promise<number> {
        try {
            return await this.instance.balanceOf(addressOwner);
        } catch (err) {
            return Promise.resolve(0);
        }
    }

    public async transfer(addressTo: string, value: string): Promise<boolean> {
        return this.instance.transfer(addressTo, value);
    }

    public async transferFrom(
        addressFrom: string,
        addressTo: string,
        value: string | BigInt
    ): Promise<boolean> {
        return await this.instance.transferFrom(addressFrom, addressTo, value);
    }

    public async approve(
        addressSpender: string,
        value: string | BigInt
    ): Promise<IWait> {
        return await this.instance.approve(addressSpender, value);
    }

    public async allowance(
        addressOwner: string,
        addressSpender: string
    ): Promise<number> {
        return await this.instance.allowance(addressOwner, addressSpender);
    }
}
