import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useRoundDispatch } from '../../context/RoundsProvider';
import { useWalletState } from '../../context/WalletProvider';
import { abi } from '../../contract/PaypoolV1Crowdsale.json';
import { CrowdSaleService } from '../../service/CrowdSaleService';
import { Erc20Service } from '../../service/Erc20Service';
import { Processing } from '../processing';
import { ProgressBar } from '../progressBar';
import { isMMError } from '../../utils/error';
import 'react-toastify/dist/ReactToastify.min.css';
import './toast.css';

import { IRound } from '../../types/round';
import { useModalContext } from '../modal/context';
import { useConfig } from '../../context/ConfigProvider';

export const RoundBuy: React.FC<IRound> = ({
    id: vestingId,
    userHardCap,
    roundHardCap,
    ratioToken,
    ownToken,
    roundName,
    soldToken,
    roundAddress,
    lockToken,
    endDate,
    vestingStart,
    tgeAward,
    claimedTge,
}) => {
    const config = useConfig();
    const [payment, setPayment] = useState(
        Object.keys(config.PURCHASE_TOKEN)[0]
    );
    const [showModal, setShowModal] = useState(false);
    const [token, setToken] = useState(0);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [waitForConfirm, setWaitForConfirm] = useState(false);
    const [waitForBuy, setWaitForBuy] = useState(false);
    const { signer, address } = useWalletState();
    const { loadRounds } = useRoundDispatch();
    const { showModal: showErrorModal } = useModalContext();
    const intl = useIntl();

    const firstCurrency = Object.keys(config.PURCHASE_TOKEN)[0];
    const secondCurrency = Object.keys(config.PURCHASE_TOKEN)[1];
    const multiplier = 10 ** config.decimal;

    useEffect(() => {
        setPayment(Object.keys(config.PURCHASE_TOKEN)[0]);
    }, [config]);

    const handleClickModal = () => {
        setShowModal(!showModal);
        setToken(0);
    };
    const handleClickBuyToken = async () => {
        if (!signer) {
            return;
        }
        setWaitForBuy(true);
        try {
            const crowdsale = new CrowdSaleService(roundAddress, abi, signer);

            const tokenAmount = Number(
                token * ratioToken * multiplier
            ).toLocaleString('fullwide', {
                useGrouping: false,
                maximumFractionDigits: 0,
            });

            const res = await crowdsale.buyTokens(
                address,
                config.PURCHASE_TOKEN[payment],
                tokenAmount
            );
            await res.wait();
            setShowModal(false);
            setToken(0);
            setIsConfirmed(false);
            loadRounds(address, signer);
        } catch (err: unknown) {
            console.log(err);
            consoleError(err);
        } finally {
            setWaitForBuy(false);
        }
    };

    const handleClickConfirm = async () => {
        if (!signer || !token) {
            return;
        }
        setWaitForConfirm(true);

        try {
            const erc20Service = new Erc20Service(
                config.PURCHASE_TOKEN[payment],
                signer
            );

            const tokenAmount = Number(
                token * ratioToken * multiplier
            ).toLocaleString('fullwide', {
                useGrouping: false,
                maximumFractionDigits: 0,
            });
            const approve = await erc20Service.approve(
                roundAddress,
                tokenAmount
            );
            await approve.wait();

            setIsConfirmed(true);
            setWaitForConfirm(false);
        } catch (err: unknown) {
            consoleError(err);
        }
    };

    const handleChangeToken = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        const leftToken = userHardCap - ownToken;
        const tokenValue = Number(value);

        if (tokenValue > leftToken) {
            setToken(leftToken);
            return;
        }

        if (tokenValue < 0) {
            setToken(0);
            return;
        }
        setToken(tokenValue);
    };

    const handleClickCurrency = (currency: string) => {
        setPayment(currency);
    };

    const notify = (message: string) => {
        toast.error(message, {
            className: 'black-background',
        });
    };

    const consoleError = (err: unknown) => {
        if (isMMError(err)) {
            if (err.code === 4001) {
                setShowModal(false);
                showErrorModal(
                    intl.formatMessage({
                        id: 'error.transactionRejectedTitle',
                    }),
                    intl.formatMessage({ id: 'error.transactionRejected' })
                );
                return;
            }
            if (err.code === -32603) {
                if (
                    err.data?.message.includes(
                        'transfer amount exceeds balance'
                    )
                ) {
                    setShowModal(false);
                    showErrorModal(
                        intl.formatMessage({
                            id: 'error.insufficientFundsTitle',
                        }),
                        intl.formatMessage({ id: 'error.insufficientFunds' })
                    );
                    return;
                }
                notify(err.data?.message || '');
                return;
            }
            console.error(err.message);
            notify(err.message);
            return;
        }
        notify('Transaction failed, please try again');
    };
    return (
        <div className="bg-darkpool-gray p-7" id={`vesting_id_${vestingId}`}>
            <ToastContainer
                autoClose={false}
                toastClassName={() => 'bg-black'}
            />

            <ProgressBar
                roundName={roundName}
                soldToken={soldToken}
                roundHardCap={roundHardCap}
            />
            <div className="h-px w-full mt-4 mb-2"></div>
            <div className="rounded-lg shadow-md">
                <div className="flex justify-between">
                    <div>
                        <p className="text-base tracking-tight text-darkpool-textGrey">
                            <FormattedMessage
                                id="allowedToBuy"
                                values={{
                                    amount: userHardCap - ownToken,
                                    token: config.tokenSymbol,
                                }}
                            />
                        </p>
                        <p className="text-base tracking-tight text-darkpool-textGrey mt-1">
                            <FormattedMessage
                                id="haveToken"
                                values={{
                                    amount: ownToken,
                                    token: config.tokenSymbol,
                                }}
                            />
                        </p>
                        <p className="text-base tracking-tight text-darkpool-textGrey mt-1">
                            <FormattedMessage
                                id="cliffStart"
                                values={{ date: endDate }}
                            />
                        </p>
                        <p className="text-base tracking-tight text-darkpool-textGrey mt-1">
                            <FormattedMessage
                                id="vestingStart"
                                values={{ date: vestingStart }}
                            />
                        </p>
                    </div>
                </div>
                <div className="sm:flex justify-between items-center mt-10">
                    <span className="text-3xl font-bold text-gray-900 dark:text-white">
                        1 {config.tokenSymbol} = {ratioToken}{' '}
                        {Object.keys(config.PURCHASE_TOKEN)[0]}
                    </span>

                    <div className="flex mt-5 sm:mt-0">
                        {/* <div className="mr-8">
                            <Claim
                                value={claim}
                                vestingAddress={vestingAddress}
                                tokenAddress={tokenAddress}
                                roundId={id}
                            />
                        </div> */}
                        {!!(userHardCap - ownToken) && (
                            <button
                                onClick={handleClickModal}
                                className="text-darkpool-green text-base border-2 rounded-lg border-darkpool-green px-10 py-2"
                            >
                                <FormattedMessage id="buy" />
                            </button>
                        )}
                    </div>
                </div>
                {!!lockToken && (
                    <p className="text-xl tracking-tight text-gray-900 dark:text-white mt-4">
                        <FormattedMessage id="lockedVest" /> {lockToken}{' '}
                        {config.tokenSymbol}
                    </p>
                )}
                {tgeAward - claimedTge !== 0 && (
                    <p className="text-xl tracking-tight text-gray-900 dark:text-white mt-4">
                        <FormattedMessage id="lockedTge" /> {tgeAward}{' '}
                        {config.tokenSymbol}
                    </p>
                )}
            </div>

            {showModal && (
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative px-4 w-full max-w-2xl h-full md:h-auto">
                        <div
                            className="relative bg-darkpool-grey2 rounded-lg shadow "
                            id={`vesting_id_${vestingId}_buy`}
                        >
                            <div className="flex justify-center p-5 dark:text-white">
                                <h3>
                                    <FormattedMessage id="fillAndBuy" />
                                </h3>
                            </div>

                            <div className="flex gap-10 justify-center border-t border-b border-gray-200 dark:border-gray-600 py-4">
                                <button
                                    type="button"
                                    disabled={waitForConfirm}
                                    onClick={() =>
                                        handleClickCurrency(firstCurrency)
                                    }
                                    className={`text-white ${
                                        firstCurrency === payment
                                            ? 'bg-darkpool-green'
                                            : 'bg-darkpool-dimmed'
                                    } rounded-lg text-sm px-5 py-3 text-center`}
                                >
                                    {Object.keys(config.PURCHASE_TOKEN)[0]}
                                </button>
                                {secondCurrency && (
                                    <button
                                        type="button"
                                        disabled={waitForConfirm}
                                        onClick={() =>
                                            handleClickCurrency(secondCurrency)
                                        }
                                        className={`text-white ${
                                            secondCurrency === payment
                                                ? 'bg-darkpool-green'
                                                : 'bg-darkpool-dimmed'
                                        } rounded-lg text-sm px-5 py-3 text-center`}
                                    >
                                        {Object.keys(config.PURCHASE_TOKEN)[1]}
                                    </button>
                                )}
                            </div>
                            <div className="justify-between items-center p-6 rounded-b md:flex md:space-x-2">
                                <div>
                                    <div className="flex">
                                        <span className="text-white inline-flex items-center px-3 text-sm rounded-l-md border border-r-0 border-darkpool-grey bg-darkpool-dimmed">
                                            {config.tokenSymbol}
                                        </span>
                                        <input
                                            type="number"
                                            value={token || ''}
                                            onChange={handleChangeToken}
                                            className="text-white rounded-none rounded-r-lg block flex-1 min-w-0 w-full text-sm p-2.5 border-darkpool-grey bg-darkpool-dimmed"
                                            placeholder={intl.formatMessage(
                                                {
                                                    id: 'max',
                                                },
                                                {
                                                    amount:
                                                        userHardCap - ownToken,
                                                }
                                            )}
                                        />

                                        {!!token && (
                                            <div className="flex items-center ml-1 text-white">
                                                {(token * ratioToken).toFixed(
                                                    5
                                                )}{' '}
                                                {payment}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex align-bottom mt-4 md:mt-0">
                                    {isConfirmed ? (
                                        <button
                                            type="button"
                                            disabled={waitForBuy}
                                            onClick={handleClickBuyToken}
                                            className={`text-white rounded-lg text-sm px-5 py-3 text-center ${
                                                !waitForBuy
                                                    ? 'cursor-pointer bg-darkpool-green'
                                                    : 'cursor-default border-2 border-darkpool-dimmed text-darkpool-dimmed'
                                            }`}
                                        >
                                            <div className="flex">
                                                {waitForBuy && <Processing />}

                                                <FormattedMessage id="buy" />
                                            </div>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            disabled={waitForConfirm}
                                            onClick={handleClickConfirm}
                                            className={`text-white rounded-lg text-sm px-5 py-3 text-center ${
                                                token && !waitForConfirm
                                                    ? 'cursor-pointer bg-darkpool-green'
                                                    : 'cursor-default border-2 border-darkpool-dimmed text-darkpool-dimmed'
                                            }`}
                                        >
                                            <div className="flex">
                                                {waitForConfirm && (
                                                    <Processing />
                                                )}

                                                <FormattedMessage id="confirm" />
                                            </div>
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        onClick={handleClickModal}
                                        className="text-darkpool-green text-base border-2 rounded-lg border-darkpool-green px-8 py-2 ml-4"
                                    >
                                        <FormattedMessage id="cancel" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
