import React from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
    roundName: string;
    roundHardCap: number;
    soldToken: number;
}
export const ProgressBar: React.FC<IProps> = ({
    roundName,
    roundHardCap,
    soldToken,
}) => {
    const tokenLeftPercent = () => {
        const tokenPercent = ((soldToken / roundHardCap) * 100).toFixed();
        return `${tokenPercent}%`;
    };

    return (
        <div>
            <div className="flex justify-between mb-1 ">
                <span className="text-base font-medium dark:text-white">
                    {roundName}
                </span>
                <span className="text-sm font-medium dark:text-white">
                    <FormattedMessage
                        id="tokenLeft"
                        values={{
                            amount: (roundHardCap - soldToken).toFixed(0),
                        }}
                    />
                </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-darkpool-grey3">
                <div
                    className="bg-darkpool-green h-2.5 rounded-full"
                    style={{ width: tokenLeftPercent() }}
                ></div>
            </div>
        </div>
    );
};
