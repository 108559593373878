import { ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import abi from '../contract/erc20.json';

export interface IConfig {
    networkId: string;
    MULTISIG: string;
    DPX_TOKEN: string;
    TOKEN_A: string;
    TOKEN_B: string;
    ADDRESS: string;
    PURCHASE_TOKEN: Record<string, string>;
    tokenSymbol: string;
    tokenDecimals: number;
    decimal: number;
}

const ConfigStateProvider = React.createContext<IConfig | undefined>(undefined);

export const ConfigProvider: React.FC = ({ children }) => {
    const [state, setState] = useState<IConfig>({
        networkId: '',
        MULTISIG: '',
        DPX_TOKEN: '',
        TOKEN_A: '',
        TOKEN_B: '',
        ADDRESS: '',
        PURCHASE_TOKEN: {},
        tokenSymbol: '',
        tokenDecimals: 18,
        decimal: 0,
    });

    useEffect(() => {
        (async () => {
            try {
                await fetch(
                    `${
                        process.env.REACT_APP_CONFIG_URL ||
                        'http://localhost:3000'
                    }/config.json`
                ).then(async (response) => {
                    const data = await response.json();

                    setState((prev) => ({
                        ...prev,
                        networkId: data.REACT_APP_NETWORK_ID,
                        MULTISIG: data.REACT_APP_MULTISIG,
                        DPX_TOKEN: data.REACT_APP_DPX_TOKEN,
                        TOKEN_A: data.REACT_APP_TOKEN_A,
                        TOKEN_B: data.REACT_APP_TOKEN_B,
                        ADDRESS: data.REACT_APP_ADDRESS,
                    }));
                });
            } catch (err) {
                console.log('err', err);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (!window.ethereum || !state.TOKEN_A || !state.TOKEN_B) {
                return;
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = await provider.getSigner();
            const tokenA = await new ethers.Contract(
                state.TOKEN_A,
                abi,
                signer
            );
            const symbolA = await tokenA.symbol();
            const decimalA = await tokenA.decimals();

            const tokenB = await new ethers.Contract(
                state.TOKEN_B,
                abi,
                signer
            );
            const symbolB = await tokenB.symbol();
            const decimalB = await tokenB.decimals();

            const tokenBri = await new ethers.Contract(
                state.DPX_TOKEN,
                abi,
                signer
            );
            const symbolBRi = await tokenBri.symbol();

            if (decimalA !== decimalB) {
                throw new Error('decimal in tokens are not the same');
            }

            setState((prev) => ({
                ...prev,
                tokenSymbol: symbolBRi,
                decimal: decimalA,
                PURCHASE_TOKEN: {
                    [symbolA]: state.TOKEN_A,
                    [symbolB]: state.TOKEN_B,
                },
            }));
        })();
    }, [state.TOKEN_A, state.TOKEN_B]);

    return (
        <ConfigStateProvider.Provider value={state}>
            {children}
        </ConfigStateProvider.Provider>
    );
};

export const useConfig = (): IConfig => {
    const ctx = useContext(ConfigStateProvider);

    if (!ctx) {
        throw new Error(
            'Component beyond Config state context, please connect'
        );
    }

    return ctx;
};
